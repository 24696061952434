<template>
  <hr class="coa" />
  <div class="stafflist">
    <h1>Operations at Indiana: Crossroads of America.</h1>
    <pre><font size="3">The internal team that keeps I:COA running day and night.</font></pre>
    <hr />
    <h1>Leadership</h1>
    <pre><font size="3">Individuals tasked with Team Management and Community Oversight.</font></pre>
    <hr />
    <label
      ><img
        src="https://www.rd.com/wp-content/uploads/2019/09/GettyImages-1126205831-scaled.jpg?resize=768,497"
        width="100"
        height="100"
      />
      <br /><b><font color="red">JulesBeHere &#128736;</font></b>
      <!-- <br /><b><a href="https://github.com/JulianEPrice" style="color:red">JulesBeHere &#128736;</a></b> -->
      <br />
      <font size="3">Community Founder</font></label
    ><br />
    <label>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
        width="100"
        height="100"
      />
      <br />
      <b>AdorkableJes#4300</b> <br />
      <font size="3">Community Administrator</font></label
    ><br />
    <label
      ><img
        src="https://avatars.githubusercontent.com/u/37253938?v=4"
        width="100"
        height="100"
      /><br />
      <b
        ><a href="https://github.com/rossmdevs/" style="color: red"
          >Ross &#128736;</a
        > </b
      ><br />
      <font size="3">ITSM Manager & DevOPS Coordinator</font></label
    >
    <hr />

    <!-- Disc Staff Header -->
    <h1>Discord Community Staff</h1>
    <pre><font size="3">Our quick-witted Community Peacekeeping team.</font></pre>
    <hr />
    <label
      ><img
        src="https://cdn.indianacoa.com/staff_images/scar.webp"
        width="100"
        height="100"
      /><br />
      <b>ScarryGargoyle#9834</b>
      <i><br /><font color="red" size="2">(ON-LEAVE, DND)</font></i> <br />
      <font size="3">Server Moderator</font></label
    ><br />
    <label>
      <img
        src="https://cdn.discordapp.com/avatars/883802423485956146/01774f6a6bdec84dbbdab0cdc5e954df.webp?size=100"
        width="100"
        height="100"
      />
      <br />
      <b>Tom <font size="1">(YONDUCHA#0593)</font> </b> <br />
      <font size="3">Server Moderator</font></label
    ><br />
    <img
      src="https://cdn.indianacoa.com/staff_images/star.jpg"
      width="100"
      height="100"
    /><br />
    <b
      ><a href="https://github.com/galactic-donuts" style="color: orange"
        >Star</a
      > </b
    ><br />
    <font size="3">ICOA Developer & Server Moderator</font>
    <!-- Game Staff -->
<!--    <hr />-->
<!--    <h1>Game Staff</h1>-->
<!--    <pre><font size="3">Our quick-witted Community Peacekeeping team.</font></pre>-->
<!--    <hr />-->
<!--    <label>-->
<!--      <img-->
<!--        src="https://api.rlmtech.xyz/assets/i/Jerry_Surprised.png"-->
<!--        width="100"-->
<!--        height="100"-->
<!--      />-->
<!--      <br />-->

<!--      <b>Tiko Thunder#1138 </b> <br />-->
<!--      <font size="3">Game Server Staff</font></label-->
<!--    ><br />-->

    <!-- DEVOPS -->
    <a id="Developers"> </a>
    <h1>
      <hr />
      Developer Operations Team (DevOPS)
    </h1>
    <pre><font size="3">The team behind DevOPS at I:COA, see our current open <router-link to="/opportunities">opportunities</router-link>. </font></pre>
    <hr />
    <label></label><br />
    <br />
    <label
      ><img
        src="https://cdn.indianacoa.com/staff_images/rico.jpg"
        width="100"
        height="100"
      /><br />
      <b
        ><a href="https://github.com/ECOrzuna" style="color: orange"
          >Rico Lamb#5756</a
        > </b
      ><br />
      <font size="3">ICOA Developer</font></label
    ><br />
    <hr />
  </div>
</template>
<script>
export default {
  name: "StaffList",
  props: {
    msg: String,
  },

};
</script>

<!--- Circle Images -->
<style>
img {
  border-radius: 8px;
}
</style>

<!-- Test -->
<style>
.img-with-text {
  text-align: justify;
  /*width: [width of img];*/
}

.img-with-text img {
  display: block;
  margin: 0 auto;
}
</style>
